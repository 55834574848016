<template>
    <div class="alert alert-success" role="alert">
        <h4 class="alert-heading">Your Booking successfully cancelled!</h4>
        <p>Thank you so much for your trust and your business. <strong>{{siteName}}</strong> will always take good care of you.</p>
        <hr>
        <router-link :to="{name:'Home'}" class="btn btn-primary"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to Home </router-link>
    </div>
</template>

<script setup>
import {ref} from "vue";

const siteName = ref(process.env.VUE_APP_TITLE)
</script>

<style scoped>
body {
    background: white !important;
}
</style>
